import { ArrowRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import ModalMail from "../../../components/modalMail";
import { useMail } from "../../../shared/hooks/useModalMail";
import { usePrint } from "../../../shared/hooks/useModalPrint";
import { useSearch } from "../../../shared/hooks/useSearch";
import { DocumentVm } from "../../../shared/models";
import { DOC_PATH } from "../../../shared/utils/const";
import ActiveButtonMail from "./activeButtonMail";
import ActiveButtonPrint from "./activeButtonPrint";
import ButtonMail from "./buttonMail";
import CheckMail from "./checkMail";
import CheckPrint from "./checkPrint";

interface IDocumentsListProps {
	list: DocumentVm[];
}

export const DocumentsList = ({ list }: IDocumentsListProps) => {
	const { hasMail, showModal } = useMail();
	const { hasPrint } = usePrint();
	const { hasSearch } = useSearch();

	return (
		<>
			<ModalMail />
			{!hasSearch && !showModal && (
				<>
					<div className='fixed top-12 z-50 w-full left-0 '>
						<div className='flex justify-between items-center w-full'>
							<div className='flex items-center mt-6 bg-white z-50'>
								<div className='text-3xl font-black flex-shrink-0  ml-5'>
									<div className=''>{list && list.length > 0 && list[0].categoria}</div>
								</div>
							</div>
							<div className='bg-white top-3 relative right-4'>
								{!hasMail && !hasPrint && <ButtonMail />}
								{/* {!hasPrint && !hasMail && <ButtonPrint />} */}
								{hasMail && <ActiveButtonMail list={list} />}
								{hasPrint && <ActiveButtonPrint />}
							</div>
						</div>
					</div>
					<div className='bg-white fixed h-36 w-full z-40 top-0 left-0 border-b'></div>
				</>
			)}
			{showModal && (
				<>
					<div className='relative top-7  w-full left-0 '>
						<div className='flex justify-between items-center w-full'>
							<div className='flex items-center mt-6 '>
								<div className='text-3xl font-black flex-shrink-0'>
									<div className=''>{list && list.length > 0 && list[0].categoria}</div>
								</div>
							</div>
							<div className='bg-white top-3 relative -right-1'>
								{!hasMail && !hasPrint && <ButtonMail />}
								{/* {!hasPrint && !hasMail && <ButtonPrint />} */}
								{hasMail && <ActiveButtonMail list={list} />}
								{hasPrint && <ActiveButtonPrint />}
							</div>
						</div>
					</div>
				</>
			)}
			<div className={classNames(showModal ? "pt-[52px]" : "pt-28", "mt-4 pb-3")}>
				{list &&
					list.map((doc) => {
						return (
							<div key={doc.id} className='relative'>
								<div onClick={() => window.open(`${doc.url ? doc.url : DOC_PATH}/${doc.file}`)} className='border-b border-gray-200 flex items-center py-3'>
									<div className='flex-shrink-0'>
										<img src='https://cdn.signorprestito.it/img_privacy/pdf.png' alt='' />
									</div>
									<div className='ml-3'>
										<div className='font-medium text-primary text-xl'>{doc.nome}</div>
									</div>

									<button type='button' className='ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500'>
										<ArrowRightIcon className='h-10 w-10 text-accent inline-block' aria-hidden='true' />
									</button>
								</div>
								{<CheckMail doc={doc} />}
								{hasPrint && <CheckPrint doc={doc} />}
							</div>
						);
					})}
			</div>
			<div className='h-20'></div>
		</>
	);
};

export default DocumentsList;
