import LoggedLayout from "../../layout/logged";

interface IProps {
	children: JSX.Element;
}

const ProtectedRouteViewer: React.FC<IProps> = ({ children }) => {
	return <LoggedLayout>{children}</LoggedLayout>;
};

export default ProtectedRouteViewer;
