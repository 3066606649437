export const BASE_URL = "https://sp-privacy-pwa-api.signorprestito.com";
// export const BASE_URL = "https://localhost:5001";

export const LOGIN_API = "/api/account/login";
export const LOGOUT_API = "/api/account/logout";
export const UNREGISTERED_API = "api/account/unregistered";
export const REGISTER_API = "api/account/register";
export const CATEGORIES_API = "api/documents/categories";
export const SUBCATEGORIES_API = "api/documents/subcategories";
export const DOCUMENTS_API = "api/documents/get";
export const SEARCH_API = "api/documents/search";
export const SEND_API = "api/documents/send";
export const PRINT_API = "api/documents/print";

export const KEY_ICON_GENERIC = "generica";
export const KEY_ICON_COPY = "copia";
export const KEY_ICON_INFO = "informativi";
export const KEY_ICON_OTHER = "altri";

export const DOC_PATH = "https://cdn.signorprestito.it/PREXTA";
