import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { useList } from "../../shared/hooks/useList";
export const Viewer = () => {
	const { path, name } = useList();
	const navigate = useNavigate();

	return (
		<div className='mt-20 h-screen'>
			<div className='border-b border-gray-200 pt-4 pb-3' onClick={() => navigate(-1)}>
				<div className=' flex items-center px-4 sm:px-6'>
					<div className='flex-shrink-0'>
						<ArrowLeftIcon className='h-6 w-6 text-accent inline-block' aria-hidden='true' />
					</div>
					<div className='ml-3'>
						<div className='font-bold text-primary text-lg'>{name}</div>
					</div>
					<button type='button' className='ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'></button>
				</div>
			</div>
			<iframe title='PDF' src={`${path}`} width='100%' height='100%'></iframe>
		</div>
	);
};

export default Viewer;
