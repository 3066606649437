import create from "zustand";
import { IMail } from "../../models/types";

export const useMail = create<IMail>((set) => ({
	showModal: false,
	sended: false,
	mailList: [],
	hasMail: false,

	setShowModal: (value) => set({ showModal: value }),
	setSended: (value) => set({ sended: value }),
	setMailList: (value) => set({ mailList: value }),
	setHasMail: (value) => set({ hasMail: value }),
}));
