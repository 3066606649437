import create from "zustand";

interface IList {
	id: string;
	hasDocuments: boolean;
	path: string;
	name: string;
	setHasDocuments: (value: boolean) => void;
	setId: (value: string) => void;
	setPath: (value: string) => void;
	setName: (value: string) => void;
}

export const useList = create<IList>((set) => ({
	hasDocuments: true,
	id: "",
	path: "",
	name: "",
	setHasDocuments: (value) => set({ hasDocuments: value }),
	setId: (value) => set({ id: value }),
	setPath: (value) => set({ path: value }),
	setName: (value) => set({ name: value }),
}));
