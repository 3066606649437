import axios from "axios";
import { BASE_URL } from "../shared/utils/const";

export const axiosClient = axios.create({
	baseURL: BASE_URL,
	headers: {
		"Content-type": "application/json",
	},
	insecureHTTPParser: true,
});

axiosClient.interceptors.request.use(
	async (config) => {
		config.headers = {
			// Authorization: `Bearer ${token}`,
			Accept: "application/json",
			"Content-Type": "application/json",
			"d0Cum3N7-r34D-s3CR37-k3Y": "YfZQ&WkoP^#4MNe6Rb2gG#4!D!%vTL",
		};
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

axiosClient.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const originalRequest = error.config;
		return Promise.reject(error);
	}
);
