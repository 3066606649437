import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Fragment } from "react";
import { useToaster } from "../../shared/hooks/useToaster";

const Toaster = () => {
	const { setShow, show, title, text, type } = useToaster();

	return (
		<Fragment>
			<Transition appear show={show} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={() => setShow(false)}>
					<Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-black bg-opacity-25' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
								<Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all'>
									<Dialog.Title as='h3' className={classNames(type == "success" ? "text-success" : type == "warning" ? "text-warning" : "text-error", "text-lg font-medium leading-6 justify-center items-center flex")}>
										{type === "success" && <CheckCircleIcon className='h-10 w-10 text-success' aria-hidden='true' />}
										{type === "warning" && <ExclamationTriangleIcon className='h-10 w-10 text-warning' aria-hidden='true' />}
										{type === "error" && <XCircleIcon className='h-10 w-10 text-error' aria-hidden='true' />}

										<div className='inline-block ml-2'>{title}</div>
									</Dialog.Title>
									<div className='mt-2'>
										<p className='text-sm text-primary'>{text}</p>
									</div>

									<div className='mt-4 mx-auto'>
										<button type='button' className='button-primary' onClick={() => setShow(false)}>
											OK
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</Fragment>
	);
};

export default Toaster;
