import { usePrint } from "../../../../shared/hooks/useModalPrint";
import { DocumentVm } from "../../../../shared/models";
import { DOC_PATH } from "../../../../shared/utils/const";

interface ICheckPrintProps {
	doc: DocumentVm;
}

export const CheckPrint = ({ doc }: ICheckPrintProps) => {
	const { printList, setPrintList } = usePrint();

	const togglePrint = async (doc: DocumentVm) => {
		let listNewPrint = Object.assign([], printList);
		const i: number = await listNewPrint.findIndex((x) => x.id === doc.id);

		if (i !== -1) {
			listNewPrint.splice(i, 1);
			setPrintList(listNewPrint);
		} else {
			listNewPrint.push({ ...doc, url: doc.url ? `${doc.url}/${doc.file}` : `${DOC_PATH}/${doc.file}` } as DocumentVm);
			setPrintList(listNewPrint);
		}
	};

	return (
		<div onClick={() => togglePrint(doc)} className='absolute top-5 right-0 ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
			<input checked={printList.findIndex((x) => x.id === doc.id) !== -1} type='checkbox' className='h-8 w-8 rounded border-gray-300 text-primary focus:ring-primary' />
		</div>
	);
};

export default CheckPrint;
