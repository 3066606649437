import create from "zustand";

interface ISearch {
	query: string;
	hasSearch: boolean;

	setquery: (value: string) => void;
	sethasSearch: (value: boolean) => void;
}

export const useSearch = create<ISearch>((set) => ({
	query: "",
	hasSearch: false,

	setquery: (value) => set({ query: value }),
	sethasSearch: (value) => set({ hasSearch: value }),
}));
