import { PaperAirplaneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useMail } from "../../../../shared/hooks/useModalMail";
import { useToaster } from "../../../../shared/hooks/useToaster";
import { DocumentVm, FileToSendDto } from "../../../../shared/models";
import { DOC_PATH } from "../../../../shared/utils/const";

interface IActiveButtonMailProps {
	list: DocumentVm[];
}

export const ActiveButtonMail = ({ list }: IActiveButtonMailProps) => {
	const { setShow, setText, setTitle, setType } = useToaster();
	const { setShowModal, mailList, setHasMail, setMailList, sended, setSended } = useMail();

	const [selectAll, setselectAll] = useState(false);

	const showToasterError = () => {
		setShow(true);
		setTitle("Attenzione");
		setText("Devi selezionare almeno un documento");
		setType("warning");
	};

	const onSelectAll = () => {
		let listNew = [];
		if (!selectAll)
			list.forEach((doc) => {
				listNew.push({
					id: doc.id,
					link: `${doc.url ? doc.url : DOC_PATH}/${doc.file}`,
					name: doc.nome,
				} as FileToSendDto);
				setMailList(listNew);
			});
		else setMailList([]);

		setselectAll(!selectAll);
	};

	useEffect(() => {
		if (sended) {
			setHasMail(false);
			setSended(false);
		}
	}, [sended]);

	const showModal = () => {
		setShowModal(true);
	};

	return (
		<div className='relative flex items-center'>
			<div>
				<button onClick={() => (mailList && mailList.length > 0 ? showModal() : showToasterError())} type='button' className='button-primary-small flex justify-center mt-2'>
					<p className='text-center inline-block mr-2'>Conferma</p>
					<PaperAirplaneIcon className='h-8 w-8  inline-block' aria-hidden='true' />
				</button>
				<button onClick={() => setHasMail(false)} type='button' className='ml-4 button-primary-outline flex justify-center mt-2'>
					<p className=' text-center inline-block mr-2'>Annulla</p>
					<XMarkIcon className='h-8 w-8  inline-block' aria-hidden='true' />
				</button>
			</div>
			{/* <div onClick={() => onSelectAll()} className='absolute top-4 right-5 ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
				<div className='flex items-center'>
					<div className='text-primary mr-4'>Seleziona tutto</div>
					<div>
						<input type='checkbox' className='h-6 w-6 rounded border-gray-300 text-primary focus:ring-primary' />
					</div>
				</div>
			</div> */}
		</div>
	);
};

export default ActiveButtonMail;
