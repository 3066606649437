import _storage from "../../../api/localStorage";

const useLocalStorage = () => {
	const localstorage = _storage;

	const get = (key: string) => {
		return localstorage.get(key);
	};

	const set = (key: string, value: string | boolean) => {
		return localstorage.set(key, value);
	};

	const remove = (key: string) => {
		return localstorage.delete(key);
	};

	return { get, set, remove };
};

export default useLocalStorage;
