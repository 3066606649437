import create from "zustand";
import { IPrint } from "../../models/types";

export const usePrint = create<IPrint>((set) => ({
	sendedPrint: false,
	printList: [],
	hasPrint: false,

	setSendedPrint: (value) => set({ sendedPrint: value }),
	setPrintList: (value) => set({ printList: value }),
	setHasPrint: (value) => set({ hasPrint: value }),
}));
