import classNames from "classnames";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Combobox, Popover, Transition } from "@headlessui/react";
import { CheckIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { DocumentVm, SearchCommand, SearchResponse } from "../../shared/models";
import { debounce } from "../../shared/utils/debounce";
import { useApiMutation } from "../../api";
import { AxiosError } from "axios";
import { useToaster } from "../../shared/hooks/useToaster";
import { DOC_PATH } from "../../shared/utils/const";
import { useList } from "../../shared/hooks/useList";
import Highlighter from "react-highlight-words";
import { useSearch } from "../../shared/hooks/useSearch";

interface IProps {
	children: JSX.Element;
}

const LoggedLayout: React.FC<IProps> = ({ children }) => {
	const { setPath, setName } = useList();
	const navigate = useNavigate();
	const { query, setquery, sethasSearch } = useSearch();

	const { setShow, setText, setTitle, setType } = useToaster();

	const [selectedDoc, setSelectedDoc] = useState();

	const [filteredDocs, setfilteredDocs] = useState<DocumentVm[]>([]);

	const postSearch = useApiMutation("search", {
		onSuccess: (resp) => {
			setfilteredDocs(resp?.data.data);
			sethasSearch(true);
		},
		onError: (error: AxiosError) => {
			if (error?.response?.status === 400) {
				const resp: SearchResponse = error?.response?.data as SearchResponse;
				const listError: string[] = resp.errors;

				listError.forEach((err) => {
					setType("error");
					setTitle("Si è verificato un errore");
					setText(err);
					setShow(true);
				});
			}
		},
	});

	useEffect(() => {
		search(query);
	}, [query]);

	const search = useCallback(
		debounce((title: string) => {
			if (title === "") {
				setfilteredDocs([]);
				sethasSearch(false);
			} else
				postSearch.mutate({
					data: {
						title,
					} as SearchCommand,
					method: "POST",
				});
		}, 500),
		[]
	);

	return (
		<Fragment>
			{children}
			<>
				{/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
				<Popover className={({ open }) => classNames(open ? "inset-0 h-20 z-40 overflow-y-auto" : "", "fixed bottom-0 w-full bg-white border-t")}>
					{({ open }) => (
						<>
							<div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
								<div className='relative flex justify-between lg:gap-8'>
									<div className='flex md:absolute md:inset-y-0 md:left-0 lg:static xl:col-span-2'>
										<div className='flex flex-shrink-0 items-center'>
											<div onClick={() => navigate("/home")} className='fixed bg-logo-small bg-contain h-12 w-8 bg-no-repeat bottom-4 left-5'></div>
										</div>
									</div>
									<div className='min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6'>
										<div className='flex items-center px-6 py-4 mx-auto max-w-xl'>
											<div className='w-full'>
												<label htmlFor='search' className='sr-only'>
													Cerca
												</label>
												<div className='relative'>
													<Combobox as='div' value={selectedDoc} onChange={setSelectedDoc}>
														<div className='relative mt-1'>
															<Combobox.Input
																placeholder='Cerca documento'
																className='w-full text-2xl rounded-3xl border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary'
																onChange={(event) => setquery(event.target.value)}
																displayValue={(doc: any) => doc?.name}
															/>
															<Combobox.Button className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
																<MagnifyingGlassIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
															</Combobox.Button>

															{filteredDocs.length > 0 && (
																<Combobox.Options className='fixed bottom-20 w-full left-0 z-50 mt-1 max-h-64 mx-auto overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
																	{filteredDocs.map((doc) => (
																		<Combobox.Option key={doc.id} value={doc} className={({ active }) => classNames("relative cursor-default select-none py-6 pl-3 pr-9", active ? "bg-primary text-white" : "text-gray-900")}>
																			{({ active, selected }) => (
																				<>
																					<div onClick={() => window.open(`${doc.url ? doc.url : DOC_PATH}/${doc.file}`)} className='flex items-center'>
																						{/* <img src={person.imageUrl} alt='' className='h-6 w-6 flex-shrink-0 rounded-full' /> */}
																						<span className={classNames("ml-3 truncate text-xl", selected && "font-semibold")}>
																							<Highlighter highlightClassName='highlightClass' searchWords={[query]} autoEscape={true} textToHighlight={doc.nome} />
																						</span>
																					</div>

																					{selected && (
																						<span className={classNames("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-white" : "text-primary")}>
																							<CheckIcon className='h-5 w-5' aria-hidden='true' />
																						</span>
																					)}
																				</>
																			)}
																		</Combobox.Option>
																	))}
																</Combobox.Options>
															)}
														</div>
													</Combobox>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</Popover>
			</>
		</Fragment>
	);
};

export default LoggedLayout;
