import { useMail } from "../../../../shared/hooks/useModalMail";
import { DocumentVm, FileToSendDto } from "../../../../shared/models";
import { DOC_PATH } from "../../../../shared/utils/const";

interface ICheckMailProps {
	doc: DocumentVm;
}

export const CheckMail = ({ doc }: ICheckMailProps) => {
	const { mailList, setMailList } = useMail();

	const toggleMail = async (doc: DocumentVm) => {
		let listNew = Object.assign([], mailList);
		const i: number = await listNew.findIndex((x) => x.id === doc.id);

		if (i !== -1) {
			listNew.splice(i, 1);
			setMailList(listNew);
		} else {
			listNew.push({
				id: doc.id,
				link: `${doc.url ? doc.url : DOC_PATH}/${doc.file}`,
				name: doc.nome,
			} as FileToSendDto);
			setMailList(listNew);
		}
	};

	return (
		<div onClick={() => toggleMail(doc)} className='absolute top-5 right-0 ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
			<input checked={mailList.findIndex((x) => x.id === doc.id) !== -1} type='checkbox' className='h-8 w-8 rounded border-gray-300 text-primary focus:ring-primary' />
		</div>
	);
};

export default CheckMail;
