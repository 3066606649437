/* istanbul ignore file */
export const isServer = () => typeof window === "undefined";
export const isClient = () => !isServer();

export const _storage = {
	get: (key: string) => {
		if (isClient()) {
			try {
				let v;
				if (localStorage.getItem(key) === "undefined" || localStorage.getItem(key) === undefined) {
					return;
				}
				if (localStorage.getItem(key)) {
					v = JSON.parse(localStorage.getItem(key) || "");
					return v;
				}
				localStorage.removeItem(key);
				return v;
			} catch (e) {
				if (typeof localStorage.getItem(key) === "string") {
					return localStorage.getItem(key);
				}
				localStorage.removeItem(key);
			}
		}
	},
	set: (key: string, value: any) => {
		const v = JSON.stringify(value);
		if (isClient()) {
			try {
				localStorage.setItem(key, v);
			} catch (error) {
				throw new Error("localStorage set item cannot be added");
			}
		}
	},
	clear: () => {
		if (isClient()) {
			localStorage.clear();
		}
	},
	delete: (key: string) => {
		if (key) {
			localStorage.removeItem(key);
		}
	},
};

export default _storage;
