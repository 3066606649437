import create from "zustand";
import { IToaster } from "../../models/types";

export const useToaster = create<IToaster>((set) => ({
	type: "error",
	show: false,
	text: "",
	title: "",

	setType: (value) => set({ type: value }),
	setShow: (value) => set({ show: value }),
	setTitle: (value) => set({ title: value }),
	setText: (value) => set({ text: value }),
}));
