import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useList } from "../../shared/hooks/useList";
import { KEY_ICON_COPY, KEY_ICON_GENERIC, KEY_ICON_INFO, KEY_ICON_OTHER } from "../../shared/utils/const";

interface IBoxPrivacyProps {
	title: string;
	id: string;
	hasDocuments: boolean;
}

export const BoxPrivacy = ({ title, id, hasDocuments }: IBoxPrivacyProps) => {
	let navigate = useNavigate();

	const { setHasDocuments, setId } = useList();

	return (
		<div
			onClick={() => {
				navigate(`/list/${id}`);
				setHasDocuments(hasDocuments);
				setId(id);
			}}
			className={"h-48 relative bg-blend-lighten bg-opacity-80 shadow-md rounded-2xl  landscape:m-2 portrait:m-6 group bg-gray-50 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"}
		>
			<div>
				<span className={"rounded-lg inline-flex p-3 ring-4 ring-white absolute"}>
					{title.toLowerCase().includes(KEY_ICON_GENERIC) && <img src='https://cdn.signorprestito.it/img_privacy/generic.png' alt='' />}
					{title.toLowerCase().includes(KEY_ICON_COPY) && <img src='https://cdn.signorprestito.it/img_privacy/copie.png' alt='' />}
					{title.toLowerCase().includes(KEY_ICON_INFO) && <img src='https://cdn.signorprestito.it/img_privacy/info.png' alt='' />}
					{title.toLowerCase().includes(KEY_ICON_OTHER) && <img src='https://cdn.signorprestito.it/img_privacy/other.png' alt='' />}
				</span>
			</div>
			<div className='py-12 text-center px-10 '>
				<h3 className='text-3xl font-medium'>{title}</h3>
				<p className='mt-2 text-sm text-gray-500'></p>
			</div>
			<span className='pointer-events-none absolute bottom-6 right-6 text-gray-300 group-hover:text-gray-400' aria-hidden='true'>
				<ArrowRightIcon className='h-10 w-10 text-accent' aria-hidden='true' />
			</span>
		</div>
	);
};

export default BoxPrivacy;
