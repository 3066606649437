import { PrinterIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import { Fragment, useEffect, useState } from "react";
import { useApiMutation } from "../../../../api";
import Spinning from "../../../../components/spinning";
import { usePrint } from "../../../../shared/hooks/useModalPrint";
import { useToaster } from "../../../../shared/hooks/useToaster";
import { PrintCommand, PrintResponse } from "../../../../shared/models";

export const ActiveButtonPrint = () => {
	const [submitting, setsubmitting] = useState<boolean>(false);

	const { setShow, setText, setTitle, setType } = useToaster();
	const { setPrintList, printList, setSendedPrint, setHasPrint, sendedPrint } = usePrint();

	const showToasterError = () => {
		setShow(true);
		setTitle("Attenzione");
		setText("Devi selezionare almeno un documento");
		setType("warning");
	};

	const postPrint = useApiMutation("print", {
		onSuccess: () => {
			setsubmitting(false);

			setShow(true);
			setType("success");
			setTitle("Operazione completata");
			setText("La richiesta di stampa è stata inviata correttamente");

			setPrintList([]);
			setSendedPrint(true);
		},
		onError: (error: AxiosError) => {
			setsubmitting(false);
			if (error?.response?.status === 400) {
				const resp: PrintResponse = error?.response?.data as PrintResponse;
				const listError: string[] = resp.errors;

				listError.forEach((err) => {
					setType("error");
					setTitle("Si è verificato un errore");
					setText(err);
					setShow(true);
				});
			}
		},
	});

	const printDocs = () => {
		setsubmitting(true);

		postPrint.mutate({
			data: {
				documents: printList,
			} as PrintCommand,
			method: "POST",
		});
	};

	useEffect(() => {
		if (sendedPrint) {
			setHasPrint(false);
			setSendedPrint(false);
		}
	}, [sendedPrint]);

	return (
		<div className='relative flex items-center'>
			<div>
				<button disabled={submitting} onClick={() => (printList && printList.length > 0 ? printDocs() : showToasterError())} type='button' className='button-primary-small flex justify-center mt-2'>
					{!submitting && (
						<Fragment>
							<p className='text-center inline-block mr-2'>Stampa</p>
							<PrinterIcon className='h-8 w-8  inline-block' aria-hidden='true' />
						</Fragment>
					)}
					{submitting && (
						<Fragment>
							<Spinning /> <PrinterIcon className='h-8 w-8 ml-2 inline-block' aria-hidden='true' />
						</Fragment>
					)}
				</button>
				<button onClick={() => setHasPrint(false)} type='button' className='ml-4 button-primary-outline flex justify-center mt-2'>
					<p className=' text-center inline-block mr-2'>Annulla</p>
					<XMarkIcon className='h-8 w-8  inline-block' aria-hidden='true' />
				</button>
			</div>
		</div>
	);
};

export default ActiveButtonPrint;
