import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { QueryKey, UseMutationOptions, UseMutationResult, UseQueryOptions, UseQueryResult, useMutation, useQuery } from "react-query";
import { CATEGORIES_API, DOCUMENTS_API, LOGIN_API, LOGOUT_API, PRINT_API, REGISTER_API, SEARCH_API, SEND_API, SUBCATEGORIES_API, UNREGISTERED_API } from "../shared/utils/const";
import apiResponses from "./apiTypes";
import { axiosClient } from "./axiosConfig";

function apiRequest<Type extends keyof apiResponses>(id: Type, axiosOptions?: AxiosRequestConfig): Promise<AxiosResponse<apiResponses[Type], any>> {
	return axiosClient.request({
		url: apiUrls[id],
		...axiosOptions,
	});
}

export { apiRequest };

function useApi<Type extends keyof apiResponses>(
	id: Type,
	axiosOptions?: AxiosRequestConfig,
	keys?: QueryKey,
	queryOptions?: Omit<UseQueryOptions<AxiosResponse<apiResponses[Type], any>, unknown, AxiosResponse<apiResponses[Type], any>, Type | QueryKey[]>, "queryKey" | "queryFn">
): UseQueryResult<AxiosResponse<apiResponses[Type], any>, unknown> {
	const dependencies = keys ? [id, keys] : id;
	return useQuery(
		dependencies,
		({ signal }) => {
			return axiosClient
				.request({
					url: apiUrls[id],
					...axiosOptions,
					signal,
				})
				.catch(function (error) {
					localStorage.clear();
				});
		},
		{
			...queryOptions,
		}
	);
}
export default useApi;

function useApiMutation<Type extends keyof apiResponses>(
	id: Type, // query key
	mutationOptions?: Omit<UseMutationOptions<AxiosResponse<apiResponses[Type], any>, AxiosError, unknown, unknown>, "mutationFn">
): UseMutationResult<AxiosResponse<apiResponses[Type], any>, unknown> {
	return useMutation((mutationAxiosOptions) => {
		return axiosClient.request({
			url: apiUrls[id],
			...(mutationAxiosOptions as AxiosRequestConfig),
		});
	}, mutationOptions);
}
export { useApi, useApiMutation };

export const apiUrls: { [api in keyof apiResponses]: string } = {
	login: LOGIN_API,
	logout: LOGOUT_API,
	unregistered: UNREGISTERED_API,
	register: REGISTER_API,
	categories: CATEGORIES_API,
	subCategories: SUBCATEGORIES_API,
	documents: DOCUMENTS_API,
	search: SEARCH_API,
	mail: SEND_API,
	print: PRINT_API,
};
