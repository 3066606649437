import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { useMail } from "../../../../shared/hooks/useModalMail";

export const ButtonMail = () => {
	const { setHasMail } = useMail();

	return (
		<button onClick={() => setHasMail(true)} type='button' className='button-primary-small flex justify-center mt-2'>
			<p className='text-white text-center inline-block mr-2'>Invia tramite email</p>
			<PaperAirplaneIcon className='h-8 w-8 text-white inline-block' aria-hidden='true' />
		</button>
	);
};

export default ButtonMail;
