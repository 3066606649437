import useApi from "../../api";
import BoxPrivacy from "../../components/boxPrivacy";
import { CategoryVm } from "../../shared/models";

const Home: React.FC = () => {
	const { data: categories } = useApi("categories");

	return (
		<div className='overflow-hidden'>
			<div className='flex flex-1 h-screen pb-32 px-5 flex-col'>
				<div className='flex justify-between'>
					<div className='text-3xl font-black py-10'>Privacy e trasparenza</div>
				</div>
				<div className='px-5 '>
					<div className='overflow-hidden rounded-lg  grid grid-cols-2 gap-px divide-y-0'>
						{categories?.data?.data &&
							categories.data.data.map((cat: CategoryVm) => {
								return <BoxPrivacy hasDocuments={cat.hasDocuments} title={cat.nome} key={cat.id} id={cat.id} />;
							})}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Home;
