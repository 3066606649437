import { ArrowRightIcon, DocumentIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useList } from "../../../shared/hooks/useList";
import { CategoryVm, DocumentVm } from "../../../shared/models";
import { KEY_ICON_COPY, KEY_ICON_GENERIC, KEY_ICON_INFO, KEY_ICON_OTHER } from "../../../shared/utils/const";

interface ISubCategoriesProps {
	list: CategoryVm[];
}

export const SubCategoriesList = ({ list }: ISubCategoriesProps) => {
	const navigate = useNavigate();
	const { setHasDocuments } = useList();

	return (
		<>
			<div className='text-3xl font-black fixed top-12 z-50 w-full left-0'>
				<div className='mt-6 ml-5'>{list && list.length > 0 && list[0].macroCategoria}</div>
			</div>

			<div className='bg-white fixed h-36 w-full z-40 top-0 left-0 border-b'></div>
			<div className='mt-4  pb-3 pt-24'>
				{list &&
					list.map((cat) => {
						return (
							<div
								key={cat.id}
								onClick={() => {
									setHasDocuments(cat.hasDocuments);
									navigate(`/list/${cat.id}`);
								}}
								className='border-b border-gray-200 flex items-center  py-3'
							>
								<div className='flex-shrink-0'>
									{cat.macroCategoria?.toLowerCase().includes(KEY_ICON_GENERIC) && <img src='https://cdn.signorprestito.it/img_privacy/generic.png' alt='' />}
									{cat.macroCategoria?.toLowerCase().includes(KEY_ICON_COPY) && <img src='https://cdn.signorprestito.it/img_privacy/copie.png' alt='' />}
									{cat.macroCategoria?.toLowerCase().includes(KEY_ICON_INFO) && <img src='https://cdn.signorprestito.it/img_privacy/info.png' alt='' />}
									{cat.macroCategoria?.toLowerCase().includes(KEY_ICON_OTHER) && <img src='https://cdn.signorprestito.it/img_privacy/other.png' alt='' />}
								</div>
								<div className='ml-3'>
									<div className='font-medium text-primary text-xl'>{cat.nome}</div>
								</div>
								<button type='button' className='ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
									<ArrowRightIcon className='h-10 w-10 text-accent inline-block' aria-hidden='true' />
								</button>
							</div>
						);
					})}
			</div>
			<div className='h-20'></div>
		</>
	);
};

export default SubCategoriesList;
