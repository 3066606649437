import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApiMutation } from "../../api";
import { useList } from "../../shared/hooks/useList";
import { useMail } from "../../shared/hooks/useModalMail";
import { useSearch } from "../../shared/hooks/useSearch";
import { useToaster } from "../../shared/hooks/useToaster";
import { CategoryVm, DocumentVm, GetQuery, GetResponse, GetSubCategoriesQuery, GetSubCategoriesResponse } from "../../shared/models";
import DocumentsList from "./documents";
import SubCategoriesList from "./subCategories";

const List: React.FC = () => {
	const { id } = useParams();
	const { setShow, setText, setTitle, setType } = useToaster();
	const { hasSearch } = useSearch();
	const { showModal } = useMail();

	const { hasDocuments, setHasDocuments } = useList();
	const navigate = useNavigate();

	const [list, setlist] = useState<CategoryVm[] | DocumentVm[]>([]);

	const postSubCategories = useApiMutation("subCategories", {
		onSuccess: (resp) => {
			setlist(resp?.data.data);
		},
		onError: (error: AxiosError) => {
			if (error?.response?.status === 400) {
				const resp: GetSubCategoriesResponse = error?.response?.data as GetSubCategoriesResponse;
				const listError: string[] = resp.errors;

				listError.forEach((err) => {
					setType("error");
					setTitle("Si è verificato un errore");
					setText(err);
					setShow(true);
				});
			}
		},
	});

	const postDocuments = useApiMutation("documents", {
		onSuccess: (resp) => {
			setlist(resp?.data.data);
		},
		onError: (error: AxiosError) => {
			if (error?.response?.status === 400) {
				const resp: GetResponse = error?.response?.data as GetResponse;
				const listError: string[] = resp.errors;

				listError.forEach((err) => {
					setType("error");
					setTitle("Si è verificato un errore");
					setText(err);
					setShow(true);
				});
			}
		},
	});

	useEffect(() => {
		setlist([]);
		if (hasDocuments) {
			postDocuments.mutate({
				data: {
					categoryId: id,
				} as GetQuery,
				method: "POST",
			});
		} else {
			postSubCategories.mutate({
				data: {
					categoryId: id,
				} as GetSubCategoriesQuery,
				method: "POST",
			});
		}
	}, [id]);

	return (
		<div className=''>
			<div className='flex flex-1  h-screen flex-col'>
				<div className=' bg-white py-5 px-5'>
					{!hasSearch && (
						<div
							className='text-lg flex items-center fixed z-50'
							onClick={() => {
								setHasDocuments(false);
								navigate(-1);
							}}
						>
							<ArrowLeftIcon className='h-6 w-6 text-primary inline-block' aria-hidden='true' />
							<div className='ml-2 z-50 text-md inline-block'>Indietro</div>
						</div>
					)}
					{hasDocuments && <DocumentsList list={list as DocumentVm[]} />}
					{!hasDocuments && <SubCategoriesList list={list as CategoryVm[]} />}
				</div>
			</div>
		</div>
	);
};

export default List;
