import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { Navigate } from "react-router-dom";
import Toaster from "./components/toaster";
import Home from "./pages/home";
import List from "./pages/list";
import Viewer from "./pages/viewer";
import useLocalStorage from "./shared/hooks/useLocalStorage";
import ProtectedRoute from "./shared/protectedRoute";

const queryClient = new QueryClient();

const App: React.FC = () => {
	const { get } = useLocalStorage();

	return (
		<QueryClientProvider client={queryClient}>
			<Toaster />
			<Router>
				<div className='font-Lato text-primary overflow-x-hidden'>
					<Routes>
						<Route
							path='/'
							element={
								<ProtectedRoute>
									<Home />
								</ProtectedRoute>
							}
						></Route>

						{/* <Route path='/login' element={<Login />} /> */}
						<Route
							path='/list/:id'
							element={
								<ProtectedRoute>
									<List />
								</ProtectedRoute>
							}
						/>
						<Route
							path='/viewer'
							element={
								<ProtectedRoute>
									<Viewer />
								</ProtectedRoute>
							}
						/>
						<Route path='*' element={<Navigate to='/' />} />
					</Routes>
				</div>
			</Router>
		</QueryClientProvider>
	);
};
export default App;
