import { Dialog, Transition } from "@headlessui/react";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import classNames from "classnames";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useApiMutation } from "../../api";
import { useMail } from "../../shared/hooks/useModalMail";
import { useToaster } from "../../shared/hooks/useToaster";
import { MailCommand, SendCommand, SendResponse } from "../../shared/models";
import { mailYup } from "../../shared/utils/yupVallidator";
import Spinning from "../spinning";

export const ModalMail = () => {
	const [submitting, setsubmitting] = useState(false);

	const { setShow, setText, setTitle, setType } = useToaster();
	const { setShowModal, showModal, mailList, setMailList, setSended } = useMail();
	const { register, handleSubmit, formState, reset } = useForm<MailCommand>({
		resolver: yupResolver(mailYup),
	});

	const { errors } = formState;

	const postMail = useApiMutation("mail", {
		onSuccess: (response) => {
			setsubmitting(false);

			const mail: SendResponse = response.data as SendResponse;
			setShowModal(false);

			setShow(true);
			setType("success");
			setTitle("Operazione completata");
			setText("L' email è stata inviata correttamente");

			reset({ to: "" });

			setMailList([]);
			setSended(true);
		},
		onError: (error: AxiosError) => {
			setsubmitting(false);
			if (error?.response?.status === 400) {
				const resp: SendResponse = error?.response?.data as SendResponse;
				const listError: string[] = resp.errors;

				listError.forEach((err) => {
					setType("error");
					setTitle("Si è verificato un errore");
					setText(err);
					setShow(true);
				});
			}
		},
	});

	const onSubmitData = async (data: MailCommand) => {
		setsubmitting(true);

		postMail.mutate({
			data: {
				mailTo: data.to,
				files: mailList,
			} as SendCommand,
			method: "POST",
		});
	};

	return (
		<Fragment>
			<Transition appear show={showModal} as={Fragment}>
				<Dialog as='div' className='relative z-10' onClose={() => setShowModal(false)}>
					<Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-black bg-opacity-25' />
					</Transition.Child>

					<div className='fixed inset-0 overflow-y-auto'>
						<div className='flex min-h-full items-center justify-center p-4 text-center'>
							<Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
								<Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all'>
									<Dialog.Title as='h3' className={"text-lg font-medium leading-6 justify-center items-center flex"}>
										<div className='inline-block ml-2'>Inserisci destinatario</div>
									</Dialog.Title>
									<form className='mt-8 space-y-6' onSubmit={handleSubmit(onSubmitData)}>
										<div className='mt-2'>
											<div className='text-lg w-full block mb-10'>
												<div className='relative mt-1 rounded-md'>
													<div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
														<PaperAirplaneIcon className='h-5 w-5 text-primary' aria-hidden='true' />
													</div>
													<input
														autoCapitalize='false'
														{...register("to")}
														type={"text"}
														name='to'
														id='to'
														className={classNames("rounded-3xl h-14 block w-full border pl-10  outline-0", errors.to ? "border-red-600 " : "border-secondary ")}
														autoComplete='off'
														placeholder='Email'
													/>
												</div>
												{errors.to && errors && (
													<p className='mt-2 text-sm text-red-600' id='email-error'>
														{errors.to.message}
													</p>
												)}
											</div>
										</div>

										<div className='mt-4 mx-auto flex'>
											<button disabled={submitting} type='submit' className='button-primary'>
												{!submitting && <Fragment>INVIA</Fragment>}
												{submitting && <Spinning />}
											</button>
											<button type='button' onClick={() => setShowModal(false)} className=' button-primary-outline-full  ml-6'>
												ANNULLA
											</button>
										</div>
									</form>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</Fragment>
	);
};

export default ModalMail;
